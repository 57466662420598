export const environment = {
  production: true,
  COMMON_API_ENDPOINT: "https://uem.stage.iops.int.sap/common-svc",
  ES_API_ENDPOINT: "https://uem.stage.iops.int.sap/elasticsearch-internal",
  CIO_API_ENDPOINT:'https://iops-s.azure-api.net/api/cio',
  AZURE_FUNC_ENDPOINT:'https://iops-s.azure-api.net/api/function',
  SSO_URL:'https://ycloudstg.accounts400.ondemand.com/saml2/idp/sso?sp=iops-stg',
  SAC_TENANT_NAME: 'integratedops-stg.eu10.hcs.cloud.sap',
  SAC_TENANT_ID: 'b510b',
  SAC_STORY_ID: 'A4906506591D8D41D02DD69A9AEC208A'
};


